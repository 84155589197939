<template>
  <div class="container">
    <div>
      <div class="query">
        <div class="search">
          <a-input placeholder="输入病案号或操作医生" type="text" v-model="patientnum" />
        </div>
        <div class="rangePicker">
          <a-range-picker show-time v-model="date" value-format="YYYY-MM-DD HH:mm:ss" />
        </div>
        <div class>
          <a-button type="primary" @click="search">搜索</a-button>
        </div>
        <div class="export" v-if="getJurisdictionList.indexOf('patientinoperation-downloadBtn') > -1
            ">
          <a-button type="primary" :loading="iconLoading" @click="derive">数据下载</a-button>
        </div>
      </div>

      <div v-if="getJurisdictionList.indexOf('patientinoperation-checkList') > -1">
        <ant-tabel :data="data" :columns="columns" :loading="loading" :status="status"
          :pagination="pagination" @changePaging="changePaging" recordKey="historyRecordId"></ant-tabel>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";

import file from "@/utils/file";

const columns = [
  {
    title: "病案号",
    dataIndex: "recordNumber",
    key: "recordNumber",
    width: 150
  },
  {
    title: "操作医生",
    dataIndex: "realName",
    key: "realName",
    width: 150
  },
  {
    title: "年龄",
    dataIndex: "age",
    key: "age",
    width: 150,
  },
  {
    title: "本次血糖",
    dataIndex: "gls",
    key: "gls",
    width: 150,
  },
  {
    title: "目前RI速度",
    dataIndex: "insulinSpeed",
    key: "insulinSpeed",
    width: 200,
  },
  {
    title: "目前糖输入速度",
    dataIndex: "gir",
    key: "gir",
    width: 150,
  },
  {
    title: "两次间隔时间",
    dataIndex: "timeDiff",
    key: "timeDiff",
    width: 150,
  },
  {
    title: "采血部位",
    dataIndex: "bloodCollection",
    key: "bloodCollection",
    width: 150,
  },
  {
    title: "监测方法",
    dataIndex: "monitorMethod",
    key: "monitorMethod",
    width: 150,
  },
  {
    title: "建议RI速度(u/h)",
    dataIndex: "adviseInsulinSpeed",
    key: "adviseInsulinSpeed",
    width: 150,
  },
  {
    title: "实际RI速度(u/h)",
    dataIndex: "proposalAdviseInsulinSpeed",
    key: "proposalAdviseInsulinSpeed",
    width: 150,
  },
  {
    title: "建议静脉推注",
    dataIndex: "iv",
    key: "iv",
    width: 150,
  },
  {
    title: "实际静脉推注",
    dataIndex: "proposalIv",
    key: "proposalIv",
    width: 150,
  },
  {
    title: "建议糖输入速度",
    dataIndex: "inputSugarMl",
    key: "inputSugarMl",
    width: 150,
  },
  {
    title: "实际糖输入速度",
    dataIndex: "proposalInputSugarMl",
    key: "proposalInputSugarMl",
    width: 150,
  },
  {
    title: "建议糖输入(g)",
    dataIndex: "inputSugar",
    key: "inputSugar",
    width: 150,
  },
  {
    title: "实际糖输入(g)",
    dataIndex: "proposalInputSugar",
    key: "proposalInputSugar",
    width: 150,
  },
  {
    title: "建议间隔时间",
    dataIndex: "period",
    key: "period",
    width: 150,
  },
  {
    title: "实际间隔时间",
    dataIndex: "proposalPeriod",
    key: "proposalPeriod",
    width: 150,
  },
  {
    title: "是否采纳",
    dataIndex: "proposalIsAdopt",
    key: "proposalIsAdopt",
    width: 150,
  },

  {
    title: "修改时间",
    dataIndex: "updateTime",
    key: "updateTime",
    width: 150,
  },
  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
    width: 150,
  },
  {
    title: "备注",
    dataIndex: "tip",
    key: "tip",
    width: 150,
  },
  {
    title: "版本：最新/历史",
    dataIndex: "proposalEdtion",
    key: "proposalEdtion",
    width: 150,
  },
];

const data = [];
export default {
  components: {
    AntTabel,
  },
  data() {
    return {
      timer: null,
      iconLoading: false,
      flag: 1,
      data,
      columns,
      loading: true,
      patientnum: "",
      date: null,
      getJurisdictionList: "",
      status: false,
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Operation Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.historyRecord();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    historyRecord() {
      this.flag = 2;
      let obj = {
        name: this.patientnum,
        pagination: this.pagination,
        date: this.date,
      };
      api
        .historyRecord(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.total;
          res.rows.forEach((data) => {
            if (!data.iv) {
              data.iv = 0;
            }
            if (!data.proposalIv) {
              data.proposalIv = 0;
            }
            if (!data.inputSugar) {
              data.inputSugar = 0;
            }
            if (!data.proposalInputSugar) {
              data.proposalInputSugar = 0;
            }
            if (!data.inputSugarMl) {
              data.inputSugarMl = "";
            }
            if (!data.proposalInputSugarMl) {
              data.proposalInputSugarMl = "";
            }
            data.key = data.historyRecordId;
            this.data.push(data);
          });
          this.data = this.data;
          this.loading = false;
        })
        .catch(() => { });
    },
    search() {
      this.pagination.current = 1;
      this.historyRecord();
      this.loading = true;
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.historyRecord();
    },
    //导出
    async derive() {
      this.iconLoading = true;
      this.$store.commit("setStatus", true);
      let obj = {
        keywords: this.patientnum,
        date: this.date,
      };
      api.downloadPatientHistory(obj).then((res) => {
        file.download(res.data, res.headers, "患者历史操作数据记录.xlsx");
        this.iconLoading = false;
        this.$store.commit("setStatus", false);
        this.$message.success("下载成功");
      });
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.query {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search .ant-input {
  height: 32px;
  border-radius: 2px;
}

/deep/ .ant-calendar-picker-input {
  height: 32px;
  border-radius: 2px;
}

.ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

.rangePicker {
  width: 400px;
}

.rangePicker /deep/ .ant-calendar-picker {
  width: 100% !important;
}

.export {
  flex: 1;
  text-align: right;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
