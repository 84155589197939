<template>
  <div class="container">
    <div class="facility">
      <div>
        <a-input placeholder="请输入账号" type="text" v-model="userName" />
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <a-button type="primary" @click="add"
        v-if="getJurisdictionList.indexOf('accountManagement-addBtn') > -1">新增</a-button>
    </div>
    <!-- 表格 -->
    <ant-tabel :data="data" :columns="columns" :loading="loading" :status="status" :pagination="pagination"
      @changePaging="changePaging" :operationBtn="operationBtn" @operation="operation"
      recordKey="userObjectId"></ant-tabel>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div class="information">
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :model="ruleForm" :rules="rules">
          <div v-for="(item, i) in addDemandtList" :key="i">
            <a-form-model-item :label="item.label" :prop="item.dataIndex">
              <div v-if="item.type == 'input'">
                <div>
                  <a-input v-model="ruleForm[item.dataIndex]" :placeholder="item.placeholder"
                    :type="item.inputType && item.inputType == 'password' ? 'password' : 'text'"></a-input>
                </div>
              </div>
              <div v-if="item.type == 'select'" class="select">
                <a-select @change="(value) => handleChangeSelect(value, item)" v-model="ruleForm[item.dataIndex]"
                  :placeholder="item.placeholder">
                  <a-select-option v-for="(d, index) in item.childrens" :key="index" :value="d.accessId">
                    {{ d.name }}</a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    width: 90,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  { title: "账号", dataIndex: "userName", key: "userName" },
  { title: "姓名", dataIndex: "name", key: "name" },
  { title: "手机号", dataIndex: "phonenumber", key: "phonenumber" },
  { title: "身份", dataIndex: "accessName", key: "accessName" },
  { title: "添加时间", dataIndex: "createTime", key: "createTime" },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      getJurisdictionList: "",
      flag: 1,
      userName: "",
      imgShow: false,
      ruleForm: {
        userName: "",
        password: "",
        name: "",
        phonenumber: "",
        accessId: undefined,
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          { min: 1, max: 50, message: "长度不超过50个字符", trigger: "change" }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { min: 6, max: 20, message: "长度应在6-20个字符", trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*~`=\-+/|\\;:"',.<>?·]).{6,20}$/, message: '请输入英文大小写、数字和特殊符号', trigger: 'change'
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
          { min: 1, max: 30, message: "长度不超过30个字符", trigger: "change" }
        ],
        phonenumber: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("手机号格式错误"));
              } else {
                callback();
              }
            },
            trigger: "change"
          }
        ],
        accessId: [
          {
            required: true,
            message: "请选择身份",
            trigger: "change",
          },
        ],
      },
      title: "新增",
      visible: false,
      data,
      columns,
      loading: true,
      status: false,
      routes: [
        {
          path: "index",
          breadcrumbName: "Account management",
        },
      ],
      // 操作按钮
      operationBtn: [],
      addDemandtList: [
        {
          type: "input",
          label: "账号:",
          dataIndex: "userName",
          placeholder: "请输入账号",
        },
        {
          type: "input",
          label: "密码:",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        },
        {
          type: "input",
          label: "姓名:",
          dataIndex: "name",
          placeholder: "请输入名称",
        },
        {
          type: "input",
          label: "手机号:",
          dataIndex: "phonenumber",
          placeholder: "请输入手机号",
        },
        {
          type: "select",
          label: "身份",
          dataIndex: "accessId",
          placeholder: "请选择身份",
          childrens: [],
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
      users: "",
    };
  },
  created() {
    // 账号列表
    this.getAccount();
    // 权限身份
    this.getIdentity();
  },
  mounted() {
    this.users = storage.getItem("users");
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
      if (
        this.getJurisdictionList.indexOf("accountManagement-compileBtn") > 0
      ) {
        this.operationBtn.push({ name: "编辑", type: "compile" });
      }
      if (this.getJurisdictionList.indexOf("accountManagement-deleteBtn") > 0) {
        this.operationBtn.push({ name: "删除", type: "deletes" });
      }
    },
    getAccount() {
      api
        .getAccount({ pagination: this.pagination, userName: this.userName })
        .then((res) => {
          this.loading = false;
          this.data = [];
          this.pagination.total = res.total;
          this.data.push(...res.rows);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getIdentity() {
      let params = {
        pagination: {
          pageSize: 100000
        }
      }
      api.getAuthority(params).then((res) => {
        res.rows.forEach((item) => {
          this.addDemandtList[4].childrens.push({
            name: item.name,
            accessId: item.accessId,
          });
        });
      });
    },
    search() {
      this.getAccount(this.userName);
      this.loading = true;
    },
    add() {
      if (this.addDemandtList[1].inputType != "password") {
        this.addDemandtList.splice(1, 0, {
          type: "input",
          label: "密码:",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        });
      }
      (this.ruleForm = {
        userName: "",
        password: "",
        name: "",
        phonenumber: "",
        accessId: undefined,
      }),
        (this.visible = true);
      this.imgShow = false;
      this.flag = 1;
    },
    // 编辑
    compile(data) {
      if (this.addDemandtList[1].inputType == "password") {
        this.addDemandtList.splice(1, 1);
      }
      this.flag = 2;
      this.imgShow = true;
      this.title = "编辑";
      this.visible = true;
      let { userName, name, userObjectId, phonenumber } = data;
      this.ruleForm.userName = userName;
      this.ruleForm.accessId = data.accessId;
      this.ruleForm.name = name;
      this.ruleForm.userObjectId = userObjectId;
      this.ruleForm.phonenumber = phonenumber;
      this.ruleForm.password = undefined;
    },
    customRequest() { },
    handleChangeSelect() { },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.flag == 1) {
            this.ruleForm.code = this.users.exclusiveCode; // 医院专属码
            api
              .addAccount(this.ruleForm)
              .then(() => {
                this.handleCancel();
                this.getAccount();
              })
          } else {
            api.amendAccount(this.ruleForm).then((res) => {
              this.handleCancel();
              this.getAccount();
            });
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$refs.ruleForm.clearValidate();
      this.visible = false;
    },
    //动态操作
    operation(type, data) {
      if (type == "compile") {
        this.compile(data);
      }
      if (type == "deletes") {
        this.deletes(data);
      }
    },

    // 删除
    deletes(data) {
      api.deleteAccount(data).then((res) => {
        this.getAccount();
      });
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.getAccount();
    },
  },
  watch: {},
};
</script>
<style scoped>
.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.facility .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}

.facility .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
