<template>
  <div class="home">
    <a-col :span="3">
      <Menu />
    </a-col>
    <a-col :span="21">
      <Header />
    </a-col>
  </div>
</template>
<script>
import Header from '../header/header';
import Menu from '../menu/menu.vue';

export default {
  components: {
    Header,
    Menu,
  },
  name: 'home',
  props: {},
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>
<style scoped></style>
