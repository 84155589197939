<template>
  <div class="container">
    <div class="search">
      <div class="search-left">
        <a-input placeholder="输入病案号" type="text" v-model="patientnum" />
        <a-button type="primary" @click="search">搜索</a-button>
      </div>
      <div class="export" v-if="getJurisdictionList.indexOf('patientstaticinformation-downloadBtn') > -1">
        <a-button type="primary" @click="derive">下载</a-button>
      </div>
    </div>

    <div v-if="getJurisdictionList.indexOf('patientstaticinformation-checkList') > -1" class="table-plate">
      <ant-tabel :data="data" :columns="columns" :loading="loading" :pagination="pagination"
        @changePaging="changePaging" recordKey="patientId"></ant-tabel>
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";

import file from "@/utils/file";

const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "病案号",
    dataIndex: "recordNumber",
    key: "recordNumber",
  },
  { title: "年龄", dataIndex: "age", key: "age" },
  { title: "体重(KG)", dataIndex: "weight", key: "weight" },
  { title: "是否有糖尿病", dataIndex: "isDiabetes", key: "isDiabetes" },
  {
    title: "ICU主要诊断",
    dataIndex: "icuDiagnosisName",
    key: "icuDiagnosisName",
  },
  { title: "入ICU时间", dataIndex: "icuDate", key: "icuDate" },
  { title: "出ICU时间", dataIndex: "outIcuDate", key: "outIcuDate" },
  {
    title: "出ICU时间来源",
    dataIndex: "outIcuSource",
    key: "outIcuSource",
  },
  {
    title: "转出ICU原由",
    dataIndex: "outIcuReason",
    key: "outIcuReason",
  },
  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
  }
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      data,
      columns,
      loading: true,
      patientnum: "",
      getJurisdictionList: "",
      sickdata: "",
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Staticinformation Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.patients();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    patients() {
      let obj = { name: this.patientnum, pagination: this.pagination };
      api
        .patient(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.total;
          res.rows.forEach((data) => {
            if (data.hospital) {
              data.hospitalName = data.hospital.name;
            }
            data.isDiabetes == '1'
              ? (data.isDiabetes = "是")
              : (data.isDiabetes = "否");

            data.key = data.patientId;
            this.data.push(data);
          });
          this.loading = false;
        })
        .catch(() => { });
    },

    search() {
      if (this.patientnum) {
        this.pagination.current = 1;
      }
      this.patients();
      this.loading = true;
    },

    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.patients();
    },
    //导出
    async derive() {
      this.loading = true;
      let obj = { name: this.patientnum };
      await api
        .patientDownload(obj)
        .then((res) => {
          file.download(res.data, res.headers, "患者信息数据记录.xlsx");
          this.loading = false;
        })
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-left {
  display: flex;
  align-items: center;
}

.search .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}

.search .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
