<template>
  <div class="container">
    <div class="facility">
      <div>
        <a-input placeholder="请输入医院" type="text" v-model="username" />
        <a-button type="primary" @click="search">查询</a-button>
      </div>
      <a-button type="primary" @click="add">新增</a-button>
    </div>
    <!-- 表格 -->
    <ant-tabel :data="data" :columns="columns" :loading="loading" :status="status" :pagination="pagination"
      @changePaging="changePaging" :operationBtn="operationBtn" @operation="operation"
      recordKey="hospitalId"></ant-tabel>
    <a-modal :title="title" :visible="visible" @ok="handleOk" @cancel="handleCancel">
      <div class="information">
        <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol" ref="ruleForm" :model="ruleForm" :rules="rules">
          <div v-for="(item, i) in addDemandtList" :key="i">
            <a-form-model-item :label="item.label" :prop="item.dataIndex">
              <div v-if="item.type == 'input'">
                <div>
                  <a-input v-model="ruleForm[item.dataIndex]" :placeholder="item.placeholder"
                    :type="item.inputType && item.inputType == 'password' ? 'password' : 'text'"></a-input>
                </div>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>
<script>

import api from "@/api/index.js";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";

const columns = [
  {
    title: "序号",
    dataIndex: "num",
    key: "num",
    width: 90,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  { title: "医院名称", dataIndex: "name", key: "name" },
  { title: "账号", dataIndex: "userName", key: "userName" },
  { title: "专属码", dataIndex: "code", key: "code" },
  { title: "添加时间", dataIndex: "createTime", key: "createTime" },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" },
  },
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      flag: 1,
      username: "",
      ruleForm: {
        userName: "",
        password: "",
        name: "",
        code: "",
        userObjectId: "",
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          { min: 1, max: 50, message: "长度不超过50个字符", trigger: "change" }
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { min: 6, max: 20, message: "长度应在6-20个字符", trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*~`=\-+/|\\;:"',.<>?·]).{6,20}$/, message: '请输入英文大小写、数字和特殊符号', trigger: 'change'
          },
        ],
        confirmPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          { min: 6, max: 20, message: "长度应在6-20个字符", trigger: "change" },
          {
            pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*~`=\-+/|\\;:"',.<>?·]).{6,20}$/, message: '请输入英文大小写、数字和特殊符号', trigger: 'change'
          },
          { validator: this.checkPasswordMatch, trigger: 'blur' },
        ],
        name: [
          {
            required: true,
            message: "请输入医院名称",
            trigger: "blur",
          },
          { min: 1, max: 50, message: "长度不超过50个字符", trigger: "change" }
        ],
        code: [
          {
            required: true,
            message: "请输入专属码",
            trigger: "blur",
          },
          { min: 1, max: 10, message: "长度不超过10个字符", trigger: "change" }
        ],
      },
      title: "新增",
      visible: false,
      data,
      columns,
      loading: true,
      status: false,
      routes: [
        {
          path: "index",
          breadcrumbName: "Account management",
        },
      ],
      // 操作按钮
      operationBtn: [
        { name: '编辑', type: 'compile' },
        { name: '重置密码', type: 'resetPassword' },
        { name: '删除', type: 'deletes' },
      ],
      addDemandtList: [
        {
          type: "input",
          label: "账号：",
          dataIndex: "userName",
          placeholder: "请输入账号",
        },
        {
          type: "input",
          label: "密码：",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        },
        {
          type: "input",
          label: "医院名称：",
          dataIndex: "name",
          placeholder: "请输入医院名称",
        },
        {
          type: "input",
          label: "专属码：",
          dataIndex: "code",
          placeholder: "请输入专属码",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    // 账号列表
    this.getAccount();
  },
  mounted() {
  },
  methods: {
    getAccount() {
      let params = {
        pageSize: this.pagination.pageSize,
        pageNum: this.pagination.current,
        name: this.username
      }
      api.getHospitalList(params).then((res) => {
        this.loading = false;
        this.data = [];
        this.pagination.total = res.total;
        this.data.push(...res.rows);
      });
    },
    search() {
      this.getAccount();
      this.loading = true;
    },
    add() {
      if (this.addDemandtList[1].inputType != "password") {
        this.addDemandtList.splice(1, 0, {
          type: "input",
          label: "密码:",
          inputType: "password",
          dataIndex: "password",
          placeholder: "请输入密码",
        });
      }
      (this.ruleForm = {
        userName: "",
        password: "",
        name: "",
        code: "",
        userObjectId: ""
      }),
        (this.visible = true);
      this.flag = 1;
    },
    // 编辑
    compile(data) {
      if (this.addDemandtList[1].inputType == "password") {
        this.addDemandtList.splice(1, 1);
      }
      this.flag = 2;
      this.title = "编辑";
      this.visible = true;
      let { userName, name, hospitalId, code, userObjectId } = data;
      this.ruleForm.userName = userName;
      this.ruleForm.name = name;
      this.ruleForm.id = hospitalId;
      this.ruleForm.code = code;
      this.ruleForm.userObjectId = userObjectId;
    },
    // 重置密码
    resetPassword(data) {
      this.addDemandtList = [{
        type: "input",
        label: "新密码：",
        inputType: "password",
        dataIndex: "password",
        placeholder: "请输入新密码",
      }, {
        type: "input",
        label: "重复密码：",
        inputType: "password",
        dataIndex: "confirmPassword",
        placeholder: "请输入重复密码",
      }]

      this.flag = 3;
      this.title = "重置密码";
      this.visible = true;
      this.ruleForm.userObjectId = data.userObjectId;
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //添加医院
          if (this.flag == 1) {
            let { userName, name, code, password } = this.ruleForm;
            let params = {
              name: name,
              userName: userName,
              password,
              code
            }
            api
              .createHospital(params)
              .then((res) => {
                if (res.code && res.code == 500) {
                  return;
                }
                this.handleCancel();
                this.$message.success("医院创建成功！");
                this.getAccount();
              })
              .catch((err) => {
                console.log(err.code);
              });
          } else if (this.flag == 2) {
            // 编辑医院
            let { id, userName, name, code, userObjectId } = this.ruleForm;
            let params = {
              hospitalId: id,
              name,
              userName,
              code,
              userObjectId
            }
            api.updateHospital(params).then((res) => {
              if (res.code && res.code == 500) {
                return;
              }
              this.handleCancel();
              this.$message.success("医院编辑成功！");
              this.getAccount();
            });
          } else {
            // 重置密码
            let { userObjectId, password } = this.ruleForm;
            let params = {
              userObjectId: userObjectId,
              password
            }
            api.updateHospitalPassword(params).then((res) => {
              if (res.code && res.code == 500) {
                return;
              }
              this.handleCancel();
              this.$message.success("重置密码成功！");
              this.getAccount();
            });
          }
        } else {
          return false;
        }
      });
    },
    handleCancel() {
      this.$refs.ruleForm.clearValidate();
      this.visible = false;
    },
    //动态操作
    operation(type, data) {
      if (type == "compile") {
        this.compile(data);
      }
      if (type == "deletes") {
        this.deletes(data);
      }
      if (type == "resetPassword") {
        this.resetPassword(data);
      }
    },
    // 删除
    deletes(data) {
      let params = {
        id: data.hospitalId,
      }
      api.updatedHospitalStatus(params).then((res) => {
        this.$message.success("医院删除成功！");
        this.getAccount();
      });
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.getAccount();
    },
    checkPasswordMatch(rule, value, callback) {
      if (value !== this.ruleForm.password) {
        callback(new Error('两次密码输入不一致'));
      } else {
        callback();
      }
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.facility {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.facility .ant-input {
  width: 210px;
  height: 32px;
  border-radius: 2px;
}

.facility .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}

/deep/ .ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .ant-modal {
  top: 0;
}

/deep/ .ant-modal-body {
  padding-bottom: 4px;

  .ant-form-item {
    margin-bottom: 16px;
  }
}

/deep/ .ant-modal-footer {
  display: flex;
  justify-content: center;
  border-top: unset;
  padding: 0 0 24px;

  .ant-btn {
    width: 74px;
    height: 32px;
    border-radius: 2px;
  }

  .ant-btn-primary {
    background: #2468f2;
  }
}
</style>