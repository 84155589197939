let menuList = [

    {
        key: 'healthcare',
        title: '医护管理',
        path: '/healthcare',
        icon: require('@/assets/image/medical_care.png')
    },
    {
        key: 'patientmanagement',
        title: '患者管理',
        path: '/patientmanagement',
        icon: require('@/assets/image/patient.png'),
        children: [
            {
                key: 'patientinformation',
                title: '患者信息记录',
                path: '/patientinformation',
            },
            {
                key: 'patientinoperation',
                title: '患者历史操作记录',
                path: '/patientinoperation',
            },
            {
                title: '患者静态信息',
                key: 'patientstaticinformation',
                path: '/patientstaticinformation',
            },
        ]
    },
    {
        title: "数据分析",
        key: "dataanalysis",
        path: "/dataanalysis",
        icon: require('@/assets/image/data_analysis.png'),
        children: [
            {
                title: "血糖质量控制",
                key: "bloodglucosequality",
                path: "/bloodglucosequality",
            },
            {
                title: "医护行为分析",
                key: "behavioranalysis",
                path: "/behavioranalysis",
            },
        ]

    },
    {
        title: "医院管理",
        key: "hospitalmanagement",
        path: "/hospitalmanagement",
        icon: require('@/assets/image/hospital.png')
    },
    {
        title: "系统设置",
        key: "systemSettings",
        path: "/systemSettings",
        icon: require('@/assets/image/system.png'),
        children: [
            {
                key: 'accountManagement',
                title: '账号管理',
                path: '/accountManagement',
            },
            {
                key: 'authorityManagement',
                title: '权限管理',
                path: '/authorityManagement',
            },
        ]
    },
];

export default menuList