<template>
  <div class="container">
    <div class="search">
      <div class="search-left">
        <a-input placeholder="输入姓名" type="text" v-model="realName" />
        <a-button type="primary" @click="Healthcare">搜索</a-button>
      </div>
    </div>
    <div v-if="getJurisdictionList.indexOf('healthcare-checkList') > -1">
      <ant-tabel :data="data" :columns="columns" :loading="loading" :pagination="pagination"
        @changePaging="changePaging" recordKey="phonenumber"></ant-tabel>
    </div>
  </div>
</template>
<script>
import api from '@/api/index.js';
import AntTabel from '../Ant-tabel/Ant-tabel.vue';
import storage from '../../storage/storage.js';
const columns = [
  {
    title: 'ID',
    dataIndex: 'num',
    key: 'num',
    width: 90,
    align: 'center',
    scopedSlots: { customRender: 'num' },
  },
  {
    title: '头像',
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
    width: 120,
    scopedSlots: { customRender: 'platform' },
  },
  { title: '昵称', dataIndex: 'nickName', key: 'nickName', width: 250 },
  { title: '真实姓名', dataIndex: 'realName', key: 'realName' },
  { title: '手机号', dataIndex: 'phonenumber', key: 'phonenumber' },
  { title: '医院', dataIndex: 'hospitalName', key: 'hospitalName' }
];

const data = [];
export default {
  components: { AntTabel },
  data() {
    return {
      loading: true,
      data,
      columns,
      routes: [
        {
          path: 'index',
          breadcrumbName: 'Health-Care management',
        },
      ],
      getJurisdictionList: '',
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
      realName: ''
    };
  },
  mounted() {
    this.Healthcare();
    this.getJurisdiction();
  },
  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem('getJurisdiction');
    },
    Healthcare() {
      this.data = [];
      let params = {
        current: this.pagination.current,
        pageSize: this.pagination.pageSize,
        realName: this.realName
      }
      api.healthcare(params).then((res) => {
        this.data = res.rows;
        this.pagination.total = res.total;
        this.loading = false;
      });
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.Healthcare();
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-left {
  display: flex;
  align-items: center;

  .ant-input {
    width: 210px;
    height: 32px;
    border-radius: 2px;
  }
}

.search .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 260px) !important;
}
</style>
