<template>
  <div class="container">
    <div v-if="state">
      <div class="search">
        <div class="search-left">
          <a-input placeholder="输入病案号" type="text" v-model="patientnum" />
          <a-button type="primary" @click="search">搜索</a-button>
        </div>
        <div class="export" v-if="getJurisdictionList.indexOf('patientinformation-downloadBtn') > -1
      ">
          <a-button type="primary" @click="derive">下载</a-button>
        </div>
      </div>

      <div v-if="getJurisdictionList.indexOf('patientinformation-checkList') > -1">
        <ant-tabel :data="data" :columns="columns" :loading="loading" @sick="sick" :status="status"
          @pitch="pitch" :pagination="pagination" @changePaging="changePaging" recordKey="patientId"></ant-tabel>
      </div>
    </div>
    <div v-if="!state">
      <div>
        <sickinformation :sickdata="sickdata"></sickinformation>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import sickinformation from "../sickinformation/sickinformation";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";

import file from "@/utils/file";

const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "病案号",
    dataIndex: "recordNumber",
    key: "recordNumber",
  },
  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operations" },
    width: 300,
  },
];

const data = [];
export default {
  components: { AntTabel, sickinformation },
  data() {
    return {
      data,
      columns,
      state: true,
      loading: true,
      patientnum: "",
      getJurisdictionList: "",
      status: true,
      sickdata: "",
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Information Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.Patient();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    Patient() {
      let obj = { name: this.patientnum, pagination: this.pagination };
      api
        .patient(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.total;
          res.rows.forEach((data) => {
            if (data.hospital) {
              data.hospitalName = data.hospital.name;
            }
            data.key = data.patientId;
            this.data.push(data);
          });
          this.loading = false;
        })
        .catch(() => { });
    },
    opens(data) {
      this.state = data;
    },
    search() {
      if (this.patientnum) {
        this.pagination.current = 1;
      }
      this.Patient();
      this.loading = true;
    },
    sick(data) {
      storage.removeItem("sickdata");
      storage.setItem("sickdata", data);
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.Patient();
    },
    //选中
    pitch(data) {
      this.mergeData = data;
    },
    //导出
    derive() {
      if (this.mergeData.length > 0) {
        api.exportGlsRecord({ patientIds: this.mergeData }).then((res) => {
          file.download(res.data, res.headers, "患者信息数据记录.xlsx");
        });
      } else {
        this.$message.warning("请选择要导出的数据");
      }
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.text) {
          setTimeout(() => {
            this.state = false;
          }, 100);
        } else {
          this.state = true;
        }
      },
      deep: true,
    },

    state: {
      handler(newName) {
        if (newName && this.$route.query.text) {
          this.state = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped lang="less">
.container {
  max-height: calc(100vh - 105px);
  overflow-y: auto;
}

.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.search-left {
  display: flex;
  align-items: center;

  .ant-input {
    width: 210px;
    height: 32px;
    border-radius: 2px;
  }
}

.search .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

.export {
  flex: 1;
  text-align: right;
}

.ant-table-body {
  overflow: auto;
  max-height: calc(100vh - 290px);
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 270px) !important;
}
</style>
